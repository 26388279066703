<template>
    <div class="ag-floating-filter-input" ref="root" @click="closeCalendars">

        <v-container class="">
            <v-row>
                <v-col style="width:100%;display:flex;">
                    <v-btn-toggle v-model="operation" color="primary" mandatory density="compact"
                        style="height:26px;flex:3;">
                        <v-btn icon="mdi-equal" value="=" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-code-tags" value="<>" style="flex:1;"></v-btn>
                        <v-btn icon="mdi-null" value="" style="flex:1;"></v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>

            <v-row>
                <v-col style="width:135px" ref="pickerParent1">
                    <flat-pickr ref="picker1" v-model="date1" class="ag-input-field-input ag-text-field-input"
                        :config="configDate1" :disabled="operation == ''" @onOpen="onOpenHandler"
                        @on-change="onChangeHandler" @on-close="onCloseHandler" @ondblclick="clearDate1"></flat-pickr>
                </v-col>
                <v-col style="width:135px" ref="pickerParent2">
                    <flat-pickr ref="picker2" v-model="date2" class="ag-input-field-input ag-text-field-input"
                        :disabled="operation != '<>'" :config="configDate2" @onOpen="onOpenHandler"
                        @on-change="onChangeHandler" @on-close="onCloseHandler" @ondblclick="clearDate2"></flat-pickr>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="justify-end" style="display: flex;">
                    <v-btn color="primary" icon="mdi-delete-outline" density="compact" value="x"
                        @click="clearDates"></v-btn>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>

import { ref, watch, inject, onMounted, onUnmounted, shallowRef } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { useAppStore } from '@/stores/app';
import { useUtilities } from '@/composables/useUtilities';



export default {
    name: 'GridSelectWithIconFilter',
    components: {
        flatPickr: shallowRef(flatPickr)
    },
    setup(props, context) {
        //PROPS



        //INJECTIONS
        const dayJs = inject("dayJs");



        //CONSTANTS
        const date1 = ref(null);
        const date2 = ref(null);
        const operation = ref('<>');
        const value = ref();
        let gridField = ref(null);
        const store = useAppStore();
        const root = ref(null);
        const picker1 = ref(null);
        const picker2 = ref(null);
        const pickerParent1 = ref(null);
        const pickerParent2 = ref(null);


        //VARIABLES
        let instance1OpenedNow = false;
        let instance2OpenedNow = false;
        let instance1 = null;
        let instance2 = null;
        const config = {
            //wrap: true,
            altInput: true,
            altFormat: props.params.format === 'date' ? store.user.config.dateFormat : store.user.config.dateTimeFormat,
            dateFormat: props.params.format === 'date' ? store.user.config.dateFormat : store.user.config.dateTimeFormat,
            enableTime: props.params.format === 'date' ? false : true,
            parseDate: (datestr, format) => {
                return dayJs(datestr, format).toDate();
            },
            formatDate: function (date, format) {
                return dayJs(date).format(format);
            },
            locale: {
                "firstDayOfWeek": 1 // start week on Monday
            },
            time_24hr: true,
            /*onKeyDown: (selectedDates, dateStr, instance, event) => {
                if (event.keyCode==9){
                    setTimeout(function(){props.params.api.tabToNextCell();},1);    //Need this settimeout because otherwise it's not working.
                    event.stopPropagation();
                }
            },*/
            //...props.params.config
        }

        const configDate1 = {
            ...config, ...{
                onReady: function () {
                    instance1 = this;
                },
                onOpen: (params) => {
                    instance2.close();
                    instance1OpenedNow = true;
                }
            }
        };
        const configDate2 = {
            ...config, ...{
                onReady: function () {
                    instance2 = this;
                },
                onOpen: (params) => {
                    instance1.close();
                    instance2OpenedNow = true;
                }
            }
        };



        //COMPOSABLES
        const { utilities } = useUtilities(store);



        //WATCHERS
        watch([() => date1.value, () => date2.value, () => operation.value], ([newDate1, newDate2, newOperation]) => {
            props.params.filterChangedCallback()
        });


        const doesFilterPass = (params) => {
            if (props.params.disableFilter) {
                return true;
            }
            let field = props.params.colDef.field;

            if (!dayJs(params.data[field]).isValid() && params.data[field] !== null) {
                return false; // If the cell value is not a valid date and not null, filter it out
            }

            let cellVal = dayJs.tz(params.data[field], 'UTC');
            let date1Utc = utilities.fromUtcToLocalTimezone(date1.value);
            let date2Utc = utilities.fromUtcToLocalTimezone(date2.value);

            if (!dayJs.isDayjs(cellVal)) {
                return operation.value == '' ? true : false;
            } else {
                switch (operation.value) {
                    case '=':
                        return date1Utc.isValid() ? cellVal.isSame(date1Utc.add(1, 'hours'), 'day') : false;
                    case '<>':
                        if (date1Utc.isValid() && date2Utc.isValid()) {
                            return cellVal.isSameOrAfter(date1Utc, 'day') && cellVal.isSameOrBefore(date2Utc, 'day');
                        } else if (date1Utc.isValid()) {
                            return cellVal.isSameOrAfter(date1Utc, 'day');
                        } else {
                            return cellVal.isSameOrBefore(date2Utc, 'day');
                        }
                    case '':
                        return !cellVal;
                    default:
                        return false;
                }
            }
        }

        const isFilterActive = () => {
            return date1.value != null || date2.value != null;
        }

        const getModel = () => {
            if (!isFilterActive()) {
                return null;
            }
            
            let type;
            switch (operation.value) {
                case '=':
                    type = 'equals';
                    break;
                case '<>':
                    type = 'inRange';
                    break;
                case '':
                    type = 'empty';
                    break;
                default:
                    type = 'equals';
            }
            
            return {
                filterType: 'date',
                type: type,
                value: date1.value + ' - ' + date2.value
            };
        }

        const setModel = (model) => {
            if (model == null) {
                date1.value = null;
                date2.value = null;
            }
            //value.value = model == null ? null : model.value;*/
        }

        /*Return something to show in the floating filter*/
        const getModelAsString = () => {
            switch (operation.value) {
                case '=':
                    return date1.value || date2.value ? ('= ' + (date1.value ? date1.value : date2.value)) : '';
                case '<>':
                    if (date1.value && date2.value) {
                        return date1.value + ' ... ' + date2.value;
                    } else if (date1.value) {
                        return '>= ' + date1.value;
                    } else {
                        return '<= ' + date2.value;
                    }
                case '':
                    return 'No Date';
            }
        }

        //Called by floating filter. It's defined in defualt components
        const onFloatingFilterChanged = (type, val) => {
            /*value.value=val;
            props.params.filterChangedCallback();*/
        }



        //EVENTS
        onMounted(() => {
            document.addEventListener('mousedown', mouseDownHandler, true);
        });
        onUnmounted(() => {
            document.removeEventListener('mousedown', mouseDownHandler, true);
        });



        //METHODS
        const mouseDownHandler = (event) => {
            if (instance1.calendarContainer.contains(event.target) || instance2.calendarContainer.contains(event.target) || root.value.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        const clearDates = () => {
            date1.value = null;
            date2.value = null;
        }

        const closeCalendars = () => {
            if (!instance1OpenedNow && instance1.isOpen) {
                instance1.close();
            }
            if (!instance2OpenedNow && instance2.isOpen) {
                instance2.close();
            }
            instance1OpenedNow = false;
            instance2OpenedNow = false;
        }

        const clearDate1 = () => {
            //
        }
        const clearDate2 = () => {
            //
        }
        const onOpenHandler = () => {
            //
        }
        const onChangeHandler = () => {
            //
        }
        const onCloseHandler = () => {
            //
        }

        return {
            root,
            props,
            value,
            date1,
            date2,
            operation,
            config,
            configDate1,
            configDate2,
            picker1,
            picker2,
            pickerParent1,
            pickerParent2,
            doesFilterPass,
            isFilterActive,
            getModel,
            setModel,
            getModelAsString,
            onFloatingFilterChanged,
            mouseDownHandler,
            clearDates,
            closeCalendars,
            onChangeHandler,
            onOpenHandler,
            onCloseHandler,
            clearDate1,
            clearDate2
        }

    }
}

</script>